



















































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DivReset from '@/components/atoms/DivReset.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import StudentList from '@/components/organisms/StudentList.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import StudentInformationStatus, { StudentInfoType } from '@/components/organisms/StudentInformationStatus.vue'
import LessonInformation from '@/components/organisms/LessonInformation.vue'
import HomeworkInformation from '@/components/organisms/HomeworkInformation.vue'
import DivTitled from '@/components/molecules/DivTitled.vue'
import ModalStudentReview from '@/components/organisms/ModalStudentReview.vue'
import StudentComment from '@/components/organisms/StudentComment.vue'
import MemoPhraseSelector from '@/components/organisms/MemoPhraseSelector.vue'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import { StudentGoal } from '@/models/api/studentGoal'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import _ from 'lodash'

@Component({
  components: {
    StudentList,
    TitleBase,
    ButtonBase,
    DivReset,
    LabelBase,
    TitleTextBase,
    Conditions,
    StudentInformationStatus,
    LessonInformation,
    HomeworkInformation,
    DivTitled,
    ModalStudentReview,
    StudentComment,
    MemoPhraseSelector,
  },
})
export default class SupporterMemo extends Mixins(StudentGoalApi, ClassModeMethods) {
  private memoPhrase?: string
  private memoId?: string
  private inputMemo?: string = ''
  private lessonId = this.$route.query.lessonId

  private studentData: StudentInfoType = {}

  private notCompletedHomeworks: string[] = []

  private latestLesson: {
    date?: string
    name?: string
    period?: number
    subject?: string
    curriculums?: {
      achievement: number
      rate: { min: number; max: number }
      text: string
    }[]
  } = {}

  private studentListData: {
    userId: number
    name: string
    lessonUserId: number
    icon: string
    selected: boolean
  }[] = []

  private beforeHomeworks: {
    drillId?: number
    subjectName?: string
    curriculumName?: string
  }[] = []

  private studentReviewData?: { face?: number; comment?: string; iconDisable?: boolean } = {}

  private phaseSelected(event: Event): void {
    const target: any = event.target
    const refs: any = this.$refs
    if (target.value) {
      if (this.inputMemo === '') {
        this.inputMemo = target.value
      } else {
        this.inputMemo += '\n' + target.value
      }
      refs.memoPhraseSelector.reset()
    }
  }

  get eachHomeworkLabel(): Function {
    return (homework: { drillId?: number }) => {
      if (!_.get(homework, 'drillId')) return '未提出'
      return '提出済み'
    }
  }

  private async mounted(): Promise<void> {
    await this.loadStudentListDatas()
  }

  private async loadStudentListDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/lesson_users', { params: { lessonId: this.lessonId } })
      .then((res: any) => {
        this.studentListData = res.data.map((student: any) => {
          // TODO アイコン固定で暫定対応
          return {
            userId: student.userId,
            name: student.nickname,
            lessonUserId: student.lessonUserId,
            icon: '',
            selected: false,
          }
        })
        if (this.studentListData.length > 0) this.studentListData[0].selected = true
      })
  }

  @Watch('studentListData')
  async studentListDataChange(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadStudentData()
    await this.loadGoal()
    await this.loadStudentReviewData()
    await this.loadSupprterMemoData()
    Vue.prototype.$loading.complete()
  }

  private async loadStudentData(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student) return

    const { data: lessonData } = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}`)

    await Vue.prototype.$http.httpWithToken
      .get('lessonUsers/studentStatus', { params: { lessonId: this.lessonId, userId: student.userId } })
      .then((res: any) => {
        // 生徒情報
        const status = []
        const clasModeLabel = res.data.classModeShortName === '対策' ? 'alert' : 'information'
        status.push({ type: clasModeLabel, message: res.data.classModeShortName })
        this.beforeHomeworks = res.data.beforeHomeworks
        // 宿題
        status.push({ type: 'information', message: `宿題：${res.data.homeworkNotSubmitted ? '未提出' : '提出済み'}` })
        this.notCompletedHomeworks = res.data.notCompletedHomeworks

        // TODO アイコン固定、目標とリマインドが未定なので固定で暫定対応
        this.studentData = {
          nickname: res.data.nickname,
          id: res.data.studentCode,
          code: res.data.studentCode,
          iconUrl: '',
          school: res.data.schoolName,
          grade: res.data.gradeName,
          userId: student.userId,
          classModeCode: res.data.classModeCode,
          status: status,
        }

        // 今回の学習
        if (res.data.thisLesson) {
          this.latestLesson = {
            date: lessonData.date,
            name: lessonData.className,
            period: lessonData.period,
            curriculums: res.data.thisLesson.map((unit: any) => {
              return {
                achievement: Math.floor(unit.maxPredictedScore * 100) / 100,
                rate: {
                  min: Math.floor(unit.minPredictedScore * 100) / 100,
                  max: Math.floor(unit.maxPredictedScore * 100) / 100,
                },
                text: unit.curriculumSUnitName,
              }
            }),
          }
        }
      })
  }

  /**
   * 現在の目標取得
   */
  private async loadGoal() {
    const classModeCode = this.studentData.classModeCode || 'TJ'
    this.studentData.goal = await this.loadCurrentGoal(classModeCode, this.studentData.userId)
  }

  private async loadStudentReviewData(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student || !this.latestLesson) return
    await Vue.prototype.$http.httpWithToken
      .get('/lessonUsers/lookBack', {
        params: { lessonId: this.lessonId, userId: student.userId, classMode: this.studentData.classModeCode },
      })
      .then((res: any) => {
        this.studentReviewData = {
          face: res.data.lookBackResult,
          comment: res.data.lookBackComment,
        }
        // コメント有無
        this.studentData.status?.splice(-1, 0, {
          type: 'information',
          message: _.get(res, 'data.lookBackComment') ? '前回コメント：あり' : '前回コメント：なし',
        })
      })
  }

  private async loadSupprterMemoData(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student || !this.latestLesson) return

    const { data: memoData } = await Vue.prototype.$http.httpWithToken.get('/supporter_memos', {
      params: { lessonUserId: student.lessonUserId },
    })

    if (memoData.id) {
      this.memoId = memoData.id
      this.inputMemo = memoData.memo
    } else {
      this.memoId = ''
      this.inputMemo = ''
    }
  }

  private async saveMemo(): Promise<void> {
    const student = this.studentListData.find((student) => student.selected)
    if (!student || !this.latestLesson) return

    if (!this.inputMemo || this.inputMemo === '') {
      alert('メモを入力してください')
      return
    } else if (this.inputMemo.length > 21845) {
      alert('メモの文字数は21845文字までです')
      return
    }

    if (this.memoId === '') {
      await Vue.prototype.$http.httpWithToken
        .post('/supporter_memos', {
          lessonUserId: student.lessonUserId,
          memo: this.inputMemo,
        })
        .then((res: any) => {
          this.memoId = res.data.id
          alert('メモを保存しました。')
        })
        .catch(() => {
          alert('エラーが発生しました。ページを更新してください。')
        })
    } else {
      await Vue.prototype.$http.httpWithToken
        .patch(`/supporter_memos/${this.memoId}`, { memo: this.inputMemo })
        .then(() => {
          alert('メモを保存しました。')
        })
        .catch(() => {
          alert('エラーが発生しました。ページを更新してください。')
        })
    }
  }
}
