













import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    SelectBase,
  },
})
export default class MemoPhraseSelector extends Vue {
  private selectedPhrase: number | null = null

  private phrases: string[] = [
    '宿題を忘れていました。宿題を溜めていないか確認をお願いします。',
    '不正解が多かったようです。復習するように指示をお願いします。',
    '演習中に止まる事が多かったようです。わからない時は次へ進んで、解説動画を見るように指示をお願いします。',
  ]

  @Prop({ default: null })
  private onChangeFunction?: any

  @Prop({ default: '定型文を入力' })
  private placeholder?: string

  @Prop({ default: null })
  private model?: {}

  @Prop()
  public value!: string

  get memoPhraseValue(): string {
    return this.value
  }

  set memoPhraseValue(value: string) {
    this.$emit('input', value)
  }

  private phrasesObject(): object[] {
    const result = []
    for (let i = 0; i < this.phrases.length; i++) {
      const obj = {
        text: this.phrases[i],
        value: this.phrases[i],
      }
      result.push(obj)
    }

    return result
  }

  private changeSelect(event: Event): any {
    if ((event.target as HTMLSelectElement).value === this.placeholder) return false
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }

  private reset(): void {
    const targetElement = this.$el.getElementsByTagName('select')[0]
    if (targetElement) {
      const options = targetElement.options
      if (options) {
        targetElement.options[0].selected = true
      }
    }
  }
}
